// Dashboard
export const DASHBOARD_ACCESS = "dashboard_access";

export const SUB_MODULES_COUNT_WIDGET = "sub_modules_count_widget";
export const LEAD_STATUS_COUNT_WIDGET = "lead_status_count_widget";
export const UPCOMING_LEAVE_REQUEST_WIDGET = "upcoming_leave_request_widget";
export const ATTENDANCE_SUMMARY_COUNT = "attendance_summary_count";
export const LEAD_CONTACT_DAY_COUNT_WIDGET = "lead_contact_day_count_widget";
export const UPCOMING_AGREEMENT_RENEWAL_WIDGET =
  "upcoming_agreement_renewal_widget";
export const ASSET_SUMMARY_COUNT_WIDGET = "asset_summary_count_widget";
export const TASK_SUMMARY_HORIZONTAL_BAR_CHART_WIDGET =
  "task_summary_horizontal_bar_chart_widget";
export const EXPENSE_CHART_WIDGET = "expense_chart_widget";
export const INCOME_CHART_WIDGET = "income_chart_widget";
export const INCOME_AND_EXPENSE_WIDGET = "income_and_expense_widget";
export const PROJECT_SUMMARY_COUNT = "project_summary_count";
export const DUE_INVOICE_WIDGET = "due_invoice_widget";
export const ACCOUNT_PAYABLE_WIDGET = "account_payable_widget";
export const UPCOMING_DUE_MILESTONE_WIDGET = "upcoming_due_milestone_widget";
export const OVER_DUE_MILESTONE_WIDGET = "over_due_milestone_widget";
export const LESS_WORK_HOUR_WIDGET = "less_work_hour_widget";
export const PENDING_LEAVE_APPLICATION_WIDGET =
  "pending_leave_application_widget";
export const UPCOMING_HOLIDAY_WIDGET = "upcoming_holiday_widget";
export const NOTICE_BOARD_WIDGET = "notice_board_widget";
export const LOAN_SUMMARY_WIDGET = "loan_summary_widget";
export const TASK_CHART_WIDGET = "task_chart_widget";
export const WORK_HOUR_GRAPH_WIDGET = "work_hour_graph_widget";

//HRMS
export const HRMS_ACCESS = "hrms_access";

// Division
export const DIVISION_ACCESS = "division_access";
export const DIVISION_CREATE = "division_create";
export const DIVISION_EDIT = "division_edit";
export const DIVISION_DELETE = "division_delete";
export const DIVISION_TRASH_ACCESS = "division_trash_access";

// Department
export const DEPARTMENT_ACCESS = "department_access";
export const DEPARTMENT_CREATE = "department_create";
export const DEPARTMENT_SHOW = "department_show";
export const DEPARTMENT_EDIT = "department_edit";
export const DEPARTMENT_DELETE = "department_delete";
export const DEPARTMENT_EMPLOYEE_ADD = "department_employee_add";
export const DEPARTMENT_EMPLOYEE_ACTIVE = "department_employee_active";
export const DEPARTMENT_EMPLOYEE_INACTIVE = "department_employee_inactive";

// Designations
export const DESIGNATION_ACCESS = "designation_access";
export const DESIGNATION_CREATE = "designation_create";
export const DESIGNATION_EDIT = "designation_edit";
export const DESIGNATION_DELETE = "designation_delete";

// Roles
export const ROLES_ACCESS = "roles_access";
export const ROLES_CREATE = "roles_create";
export const ROLES_SHOW = "roles_show";
export const ROLES_EDIT = "roles_edit";
export const ROLES_DELETE = "roles_delete";

/** Users Module */

// Users
export const USERS_ACCESS = "users_access";
export const USERS_CREATE = "users_create";
export const USERS_EDIT = "users_edit";
export const USERS_DELETE = "users_delete";
export const USERS_PERMANENTLY_DELETE = "users_permanently_delete";

//User Profile
export const USERS_PROFILE_ACCESS = "users_profile_access";

//User Profile Details
export const USERS_PROFILE_DETAILS_ACCESS = "users_profile_details_access";

//User General Information
export const USERS_GENERAL_INFO_EDIT = "users_general_info_edit";

//User Contact Information
export const USERS_CONTACT_INFO_EDIT = "users_contact_info_edit";

// User Family Info
export const USERS_FAMILY_INFO_CREATE = "users_family_info_create";
export const USERS_FAMILY_INFO_EDIT = "users_family_info_edit";
export const USERS_FAMILY_INFO_DELETE = "users_family_info_delete";

// User Document
export const USERS_DOCUMENT_CREATE = "users_documents_create";
export const USERS_DOCUMENT_EDIT = "users_documents_edit";
export const USERS_DOCUMENT_DELETE = "users_documents_delete";

//User Bank Account
export const USERS_BANK_ACCOUNT_CREATE = "users_bank_account_create";
export const USERS_BANK_ACCOUNT_EDIT = "users_bank_account_edit";
export const USERS_BANK_ACCOUNT_DELETE = "users_bank_account_delete";

export const USERS_ACADEMIC_QUALIFICATION_CREATE =
  "users_academic_qualification_create";
export const USERS_ACADEMIC_QUALIFICATION_EDIT =
  "users_academic_qualification_edit";
export const USERS_ACADEMIC_QUALIFICATION_DELETE =
  "users_academic_qualification_delete";

export const USERS_EXPERIENCE_CREATE = "users_experience_create";
export const USERS_EXPERIENCE_EDIT = "users_experience_edit";
export const USERS_EXPERIENCE_DELETE = "users_experience_delete";

// User Designation
export const USERS_DESIGNATION_UPDATE = "users_designation_update";
export const USERS_DESIGNATION_DELETE = "users_designation_delete";

export const USERS_EMPLOYEE_TYPE_UPDATE = "users_employee_type_update";
export const USERS_EMPLOYEE_TYPE_DELETE = "users_employee_type_delete";

export const USERS_CUSTOM_LOG_UPDATE = "users_custom_log_update";
export const USERS_CUSTOM_LOG_DELETE = "users_custom_log_delete";

//User Salary
export const USERS_SALARY_UPDATE = "users_salary_update";
export const USERS_SALARY_DELETE = "users_salary_delete";

//User Password
export const USERS_PASSWORD_UPDATE = "users_password_update";

// Employee Types
export const EMPLOYEE_TYPES_ACCESS = "employee_types_access";
export const EMPLOYEE_TYPES_CREATE = "employee_types_create";
export const EMPLOYEE_TYPES_SHOW = "employee_types_show";
export const EMPLOYEE_TYPES_EDIT = "employee_types_edit";
export const EMPLOYEE_TYPES_DELETE = "employee_types_delete";
export const EMPLOYEE_TYPES_TRASH_ACCESS = "employee_types_trash_access";

/** Attendance Module */

// Attendance Management
export const ATTENDANCE_MANAGEMENT_ACCESS = "attendance_management_access";
export const ATTENDANCE_CALENDER_ACCESS = "attendance_calender_access";

// Attendance
export const ATTENDANCE_ACCESS = "attendance_access";
export const ATTENDANCE_SHOW = "attendance_show";
export const ATTENDANCE_DETAILS_EDIT = "attendance_details_edit";
export const ATTENDANCE_SEARCH = "attendance_search";
export const ATTENDANCE_RECONCILIATION = "attendance_reconciliation";

//  Attendance Policy
export const ATTENDANCE_POLICY_ACCESS = "attendance_policy_access";
export const ATTENDANCE_POLICY_CREATE = "attendance_policy_create";
export const ATTENDANCE_POLICY_EDIT = "attendance_policy_edit";
export const ATTENDANCE_POLICY_DELETE = "attendance_policy_delete";

//  Attendance Policy Map
export const ATTENDANCE_POLICY_MAP_ACCESS = "attendance_policy_map_access";
export const ATTENDANCE_POLICY_MAP_CREATE = "attendance_policy_map_create";
export const ATTENDANCE_POLICY_MAP_EDIT = "attendance_policy_map_edit";
export const ATTENDANCE_POLICY_MAP_DELETE = "attendance_policy_map_delete";

// Attendance Report
export const ATTENDANCE_REPORT_ACCESS = "attendance_report_access";
export const ATTENDANCE_SEE_ALL_REPORT = "attendance_see_all_report";
export const ATTENDANCE_SEE_SELF_REPORT = "attendance_see_self_report";

// Attendance Reconciliation
export const ATTENDANCE_RECONCILIATION_ACCESS =
  "attendance_reconciliation_access";
export const ATTENDANCE_RECONCILIATION_SHOW = "attendance_reconciliation_show";
export const ATTENDANCE_RECONCILIATION_EDIT = "attendance_reconciliation_edit";
export const ATTENDANCE_RECONCILIATION_CANCEL =
  "attendance_reconciliation_cancel";
export const ATTENDANCE_RECONCILIATION_APPROVE =
  "attendance_reconciliation_approve";
export const ATTENDANCE_RECONCILIATION_SELF_APPROVE =
  "attendance_reconciliation_self_approve";
export const ATTENDANCE_RECONCILIATION_APPLY =
  "attendance_reconciliation_apply";
export const ATTENDANCE_RECONCILIATION_APPLY_EMPLOYEE =
  "attendance_reconciliation_apply_employee";

// Leave Management
export const LEAVE_MANAGEMENT_ACCESS = "leave_management_access";

// Holidays
export const HOLIDAYS_ACCESS = "holidays_access";
export const HOLIDAYS_CREATE = "holidays_create";
export const HOLIDAYS_EDIT = "holidays_edit";
export const HOLIDAYS_DELETE = "holidays_delete";

// Leave Types
export const LEAVE_TYPES_ACCESS = "leave_types_access";
export const LEAVE_TYPES_CREATE = "leave_types_create";
export const LEAVE_TYPES_EDIT = "leave_types_edit";
export const LEAVE_TYPES_DELETE = "leave_types_delete";

// Leave BALANCE
export const LEAVE_BALANCE_ACCESS = "leave_balance_access";
export const LEAVE_BALANCE_VIEW_ALL = "leave_balance_view_all";
export const LEAVE_BALANCE_EDIT = "leave_balance_edit";

//Leave Requests
export const LEAVE_REQUESTS_ACCESS = "leave_requests_access";
export const LEAVE_REQUESTS_CREATE = "leave_requests_create";
export const LEAVE_REQUESTS_EDIT = "leave_requests_edit";
export const LEAVE_REQUESTS_DELETE = "leave_requests_delete";
export const LEAVE_REQUESTS_APPLICATION_CREATE =
  "leave_requests_application_create";
export const LEAVE_REQUESTS_RECONCILIATION = "leave_requests_reconciliation";
export const LEAVE_APPROVE_SEE_SELF = "leave_approve_see_self";

//Leave Approve
export const LEAVE_APPROVE_ACCESS = "leave_approve_access";
export const LEAVE_APPROVE_ACCEPT = "leave_approve_accept";
export const LEAVE_APPROVE_SELF_ACCEPT = "leave_approve_self_accept";
export const LEAVE_APPROVE_DIVISION_HEAD_ACCEPT =
  "leave_approve_division_head_accept";
export const LEAVE_APPROVE_DEPT_HEAD_ACCEPT = "leave_approve_dept_head_accept";

export const LEAVE_APPROVE_REJECT = "leave_approve_reject";
export const LEAVE_APPROVE_CANCELED = "leave_approve_canceled";
export const LEAVE_APPROVE_REVERT = "leave_revert";

/** Settings Module  */

// Settings
export const SETTINGS_ACCESS = "settings_access";
export const SETTINGS_GENERAL_ACCESS = "settings_general_access";

// Weekends
export const WEEKENDS_ACCESS = "weekends_access";
export const WEEKENDS_CREATE = "weekends_create";
export const WEEKENDS_EDIT = "weekends_edit";
export const WEEKENDS_DELETE = "weekends_delete";

/** Lead Module  */

//Lead Management
export const LEAD_MANAGEMENT_ACCESS = "lead_management_access";
export const MY_LEAD_ACCESS = "my_lead_access";
export const MY_LEAD_CREATE = "my_lead_create";
export const MY_LEAD_EDIT = "my_lead_edit";
export const MY_LEAD_DELETE = "my_lead_delete";

//Lead Category
export const LEAD_CATEGORY_ACCESS = "lead_category_access";
export const LEAD_CATEGORY_CREATE = "lead_category_create";
export const LEAD_CATEGORY_EDIT = "lead_category_edit";
export const LEAD_CATEGORY_DELETE = "lead_category_delete";

//Lead Source
export const LEAD_SOURCE_ACCESS = "lead_source_access";
export const LEAD_SOURCE_CREATE = "lead_source_create";
export const LEAD_SOURCE_EDIT = "lead_source_edit";
export const LEAD_SOURCE_DELETE = "lead_source_delete";

//Lead Status
export const LEAD_STATUS_ACCESS = "lead_status_access";
export const LEAD_STATUS_CREATE = "lead_status_create";
export const LEAD_STATUS_EDIT = "lead_status_edit";
export const LEAD_STATUS_DELETE = "lead_status_delete";

//Lead
export const LEAD_ACCESS = "lead_access";
export const LEAD_CREATE = "lead_create";
export const LEAD_EDIT = "lead_edit";
export const LEAD_DELETE = "lead_delete";

//Lead Details
export const LEAD_DETAILS_ACCESS = "lead_details_access";
export const LEAD_DETAILS_EXPORT = "lead_details_export";
export const LEAD_DETAILS_CREATE = "lead_details_create";
export const LEAD_DETAILS_EDIT = "lead_details_edit";
export const LEAD_DETAILS_DELETE = "lead_details_delete";

//Lead Activity
export const LEAD_ACTIVITY_ACCESS = "lead_activity_access";
export const LEAD_ACTIVITY_CREATE = "lead_activity_create";
export const LEAD_ACTIVITY_EDIT = "lead_activity_edit";
export const LEAD_ACTIVITY_DELETE = "lead_activity_delete";

/** Asset Module  */

//Asset Management
export const ASSET_MANAGEMENT_ACCESS = "asset_management_access";

// Asset Type
export const ASSET_TYPE_ACCESS = "asset_type_access";
export const ASSET_TYPE_CREATE = "asset_type_create";
export const ASSET_TYPE_EDIT = "asset_type_edit";
export const ASSET_TYPE_DELETE = "asset_type_delete";

// Asset
export const ASSET_ACCESS = "asset_access";
export const ASSET_EXPORT = "asset_export";
export const ASSET_REPAIR = "asset_repair";
export const ASSET_CREATE = "asset_create";
export const ASSET_EDIT = "asset_edit";
export const ASSET_DELETE = "asset_delete";

// Asset Repair History
export const ASSET_REPAIR_HISTORY_ACCESS = "asset_repair_history_access";
export const ASSET_REPAIR_HISTORY_CREATE = "asset_repair_history_create";
export const ASSET_REPAIR_HISTORY_EDIT = "asset_repair_history_edit";
export const ASSET_REPAIR_HISTORY_DELETE = "asset_repair_history_delete";

/** Notice  Module  */

// Notice
export const NOTICE_ACCESS = "notice_access";
export const NOTICE_CREATE = "notice_create";
export const NOTICE_DETAILS_ACCESS = "notice_details_access";

/** Finance  Module  */

//Finance
export const FINANCE_MANAGEMENT_ACCESS = "finance_management_access";

// Project
export const PROJECT_ACCESS = "project_access";
export const PROJECT_SHOW = "project_show";
export const PROJECT_APPROVE = "project_approve";
export const PROJECT_EDIT = "project_edit";
export const PROJECT_APPROVED_EDIT = "project_approved_edit";

//Milestone
export const MILESTONE_ACCESS = "milestone_access";
export const MILESTONE_CREATE = "milestone_create";
export const MILESTONE_EDIT = "milestone_edit";
export const MILESTONE_DELETE = "milestone_delete";
export const MILESTONE_FORWARD_ACCESS = "milestone_forward_access";

//Invoice
export const INVOICE_CREATE = "invoice_create";
export const INVOICE_COLLECT_PAYMENT_ACCESS = "invoice_collect_payment_access";
export const INVOICE_PAYMENT_ACCESS = "invoice_payment_access";

//Expense
export const EXPENSE_ACCESS = "expense_access";
export const EXPENSE_CREATE = "expense_create";
export const EXPENSE_EDIT = "expense_edit";
export const EXPENSE_DELETE = "expense_delete";

//Payslip
export const PAYSLIP_ACCESS = "payslip_access";
export const PAYSLIP_PAYMENT_ACCESS = "payslip_payment_access";

//Account Type
export const ACCOUNT_TYPE_ACCESS = "account_type_access";
export const ACCOUNT_TYPE_CREATE = "account_type_create";
export const ACCOUNT_TYPE_EDIT = "account_type_edit";
export const ACCOUNT_TYPE_DELETE = "account_type_delete";

//Account
export const ACCOUNT_ACCESS = "account_access";
export const ACCOUNT_SHOW = "account_show";
export const ACCOUNT_CREATE = "account_create";
export const ACCOUNT_EDIT = "account_edit";
export const ACCOUNT_DELETE = "account_delete";

//Account Deposit
export const ACCOUNT_DEPOSIT_ACCESS = "account_deposit_access";
export const ACCOUNT_DEPOSIT_CREATE = "account_deposit_create";

//Account Transfer
export const ACCOUNT_TRANSFER_ACCESS = "account_transfer_access";
export const ACCOUNT_TRANSFER_CREATE = "account_transfer_create";

//Transaction Histories
export const TRANSACTION_HISTORIES_ACCESS = "transaction_histories_access";

//Task Management
export const TASK_MANAGEMENT_ACCESS = "task_management_access";

//Boards
export const TASK_MANAGEMENT_BOARDS_ACCESS = "task_management_boards_access";

// Task Management Board
export const MILESTONE_BOARD_ACCESS = "milestone_board_access";
export const MILESTONE_BOARD_CREATE = "milestone_board_create";
export const MILESTONE_BOARD_EDIT = "milestone_board_edit";
export const MILESTONE_BOARD_DELETE = "milestone_board_delete";
export const MILESTONE_BOARD_SEE_SELF = "milestone_board_see_self";
export const MILESTONE_BOARD_SEE_ALL = "milestone_board_see_all";
export const MILESTONE_BOARD_UPDATE_STATUS = "milestone_board_update_status";
export const MILESTONE_BOARD_UPDATE_TASK_FINAL_PHASE =
  "milestone_board_update_task_final_phase";

// Task Management Board
export const MILESTONE_BOARD_TASK_CREATE = "milestone_board_task_create";
export const MILESTONE_BOARD_TASK_EDIT = "milestone_board_task_edit";
export const MILESTONE_BOARD_TASK_DELETE = "milestone_board_task_delete";

// Sub Task Management Board
export const MILESTONE_BOARD_SUB_TASK_CREATE =
  "milestone_board_sub_task_create";
export const MILESTONE_BOARD_SUB_TASK_EDIT = "milestone_board_sub_task_edit";
export const MILESTONE_BOARD_SUB_TASK_DELETE =
  "milestone_board_sub_task_delete";

//Global List
export const TASK_MANAGEMENT_GLOBAL_TRACKER_ACCESS =
  "task_management_global_tracker_access";

/** Loan Module  */

// Loan Management
export const LOAN_MANAGEMENT_ACCESS = "loan_management_access";

//Loan
export const LOAN_ACCESS = "loan_access";
export const LOAN_CREATE = "loan_create";
export const LOAN_SHOW = "loan_show";

//Loan Approver Access
export const LOAN_APPROVER_ACCESS = "loan_approver_access";

//Loan Policy
export const LOAN_POLICY_CREATE = "loan_policy_create";
export const LOAN_POLICY_EDIT = "loan_policy_edit";
export const LOAN_POLICY_ACCESS = "loan_policy_access";
export const LOAN_POLICY_DELETE = "loan_policy_delete";

/** Payroll */

// Payroll Management
export const PAYROLL_MANAGEMENT_ACCESS = "payroll_management_access";

//Salary Policy
export const SALARY_POLICY_ACCESS = "salary_policy_access";
export const SALARY_POLICY_CREATE = "salary_policy_create";
export const SALARY_POLICY_EDIT = "salary_policy_edit";
export const SALARY_POLICY_DELETE = "salary_policy_delete";
export const SALARY_POLICY_SHOW = "salary_policy_show";

//Project Commission Policy
export const PROJECT_COMMISSION_POLICY_ACCESS =
  "project_commission_policy_access";
export const PROJECT_COMMISSION_CREATE = "project_commission_create";
export const PROJECT_COMMISSION_EDIT = "project_commission_edit";
export const PROJECT_COMMISSION_DELETE = "project_commission_delete";
export const PROJECT_COMMISSION_SHOW = "project_commission_show";

export const MEAL_POLICY_ACCESS = "meal_policy_access";
export const MEAL_POLICY_CREATE = "meal_create";
export const MEAL_POLICY_EDIT = "meal_edit";
export const MEAL_POLICY_DELETE = "meal_delete";
export const MEAL_POLICY_SHOW = "meal_show";

//Over Time Policy
export const OVER_TIME_POLICY_ACCESS = "over_time_policy_access";
export const OVER_TIME_POLICY_CREATE = "over_time_create";
export const OVER_TIME_POLICY_EDIT = "over_time_edit";
export const OVER_TIME_POLICY_DELETE = "over_time_delete";
export const OVER_TIME_POLICY_SHOW = "over_time_show";

//Insurance Policy
export const INSURANCE_POLICY_ACCESS = "insurance_policy_access";
export const INSURANCE_POLICY_CREATE = "insurance_policy_create";
export const INSURANCE_POLICY_EDIT = "insurance_policy_edit";
export const INSURANCE_POLICY_DELETE = "insurance_policy_delete";
export const INSURANCE_POLICY_SHOW = "insurance_policy_show";

//Provident Fund Policy
export const PROVIDENT_FUND_POLICY_ACCESS = "provident_fund_policy_access";
export const PROVIDENT_FUND_POLICY_CREATE = "provident_fund_policy_create";
export const PROVIDENT_FUND_POLICY_EDIT = "provident_fund_policy_edit";
export const PROVIDENT_FUND_POLICY_DELETE = "provident_fund_policy_delete";
export const PROVIDENT_FUND_POLICY_SHOW = "provident_fund_policy_show";

//Income Tax Policy
export const INCOME_TAX_POLICY_ACCESS = "income_tax_policy_access";
export const INCOME_TAX_POLICY_CREATE = "income_tax_policy_create";
export const INCOME_TAX_POLICY_EDIT = "income_tax_policy_edit";
export const INCOME_TAX_POLICY_DELETE = "income_tax_policy_delete";
export const INCOME_TAX_POLICY_SHOW = "income_tax_policy_show";

//Festival Bonus Policy
export const FESTIVAL_BONUS_POLICY_ACCESS = "festival_bonus_policy_access";
export const FESTIVAL_BONUS_POLICY_CREATE = "festival_bonus_policy_create";
export const FESTIVAL_BONUS_POLICY_EDIT = "festival_bonus_policy_edit";
export const FESTIVAL_BONUS_POLICY_DELETE = "festival_bonus_policy_delete";
export const FESTIVAL_BONUS_POLICY_SHOW = "festival_bonus_policy_show";

//Extra Leave Policy
export const EXTRA_LEAVE_POLICY_ACCESS = "extra_leave_policy_access";
export const EXTRA_LEAVE_POLICY_CREATE = "extra_leave_policy_create";
export const EXTRA_LEAVE_POLICY_EDIT = "extra_leave_policy_edit";
export const EXTRA_LEAVE_POLICY_DELETE = "extra_leave_policy_delete";
export const EXTRA_LEAVE_POLICY_SHOW = "extra_leave_policy_show";

//Delay Fine Policy
export const DELAY_FINE_POLICY_ACCESS = "delay_fine_policy_access";
export const DELAY_FINE_POLICY_CREATE = "delay_fine_policy_create";
export const DELAY_FINE_POLICY_EDIT = "delay_fine_policy_edit";
export const DELAY_FINE_POLICY_DELETE = "delay_fine_policy_delete";
export const DELAY_FINE_POLICY_SHOW = "delay_fine_policy_show";

//Fine Policy
export const FINE_POLICY_ACCESS = "fine_policy_access";
export const FINE_POLICY_CREATE = "fine_policy_create";
export const FINE_POLICY_EDIT = "fine_policy_edit";
export const FINE_POLICY_DELETE = "fine_policy_delete";
export const FINE_POLICY_SHOW = "fine_policy_show";

//Payroll Policy Assign
export const PAYROLL_POLICY_ASSIGN = "payroll_policy_assign";

// Generate Payslip
export const SALARY_PAYSLIP_ACCESS = "salary_payslip_access";
export const SALARY_PAYSLIP_CREATE = "salary_payslip_create";
export const SALARY_PAYSLIP_SHOW = "salary_payslip_show";
export const SALARY_PAYSLIP_DELETE = "salary_payslip_delete";
export const SALARY_PAYSLIP_GENERATE = "salary_payslip_generate";

// Subscription
export const SUBSCRIPTION_ACCESS = "subscription_access";
export const SUBSCRIPTION_EDIT = "subscription_edit";

// Pricing Table
export const PRICING_TABLE_ACCESS = "pricing_table_access";

// Payment History
export const PAYMENT_HISTORY_ACCESS = "payment_history_access";

// Outreach Invoice
export const OUTREACH_INVOICE_ACCESS = "outreach_invoice_access";
export const OUTREACH_INVOICE_CREATE = "outreach_invoice_create";
export const OUTREACH_INVOICE_EDIT = "outreach_invoice_edit";
export const OUTREACH_INVOICE_DELETE = "outreach_invoice_delete";
export const OUTREACH_INVOICE_SHOW = "outreach_invoice_show";

// BACK LINK
export const BACK_LINK_ACCESS = "back_link_access";
export const BACK_LINK_CREATE = "back_link_create";
export const BACK_LINK_EDIT = "back_link_edit";
export const BACK_LINK_DELETE = "back_link_delete";
export const BACK_LINK_SHOW = "back_link_show";

//    SITE LINK
export const SITE_LIST_ACCESS = "site_list_access";
export const SITE_LIST_CREATE = "site_list_create";
export const SITE_LIST_EDIT = "site_list_edit";
export const SITE_LIST_DELETE = "site_list_delete";
export const SITE_LIST_SHOW = "site_list_show";

//   Niche
export const NICHE_ACCESS = "niche_access";
export const NICHE_CREATE = "niche_create";
export const NICHE_EDIT = "niche_edit";
export const NICHE_DELETE = "niche_delete";
export const NICHE_SHOW = "niche_show";

//   Outreach Seller
export const OUTREACH_SELLER_ACCESS = "outreach_seller_access";
export const OUTREACH_SELLER_CREATE = "outreach_seller_create";
export const OUTREACH_SELLER_EDIT = "outreach_seller_edit";
export const OUTREACH_SELLER_DELETE = "outreach_seller_delete";
export const OUTREACH_SELLER_SHOW = "outreach_seller_show";

//   Outreach Logs
export const OUTREACH_LOGS_ACCESS = "outreach_logs_access";
export const OUTREACH_LOGS_SHOW = "outreach_logs_show";


// Remote Attendance
export const REMOTE_ATTENDANCE_ACCESS = "remote_attendance_access";
export const REMOTE_ATTENDANCE_CREATE = "remote_attendance_create";
export const REMOTE_ATTENDANCE_EDIT = "remote_attendance_edit";
export const REMOTE_ATTENDANCE_DELETE = "remote_attendance_delete";