import { MILESTONE_BOARD_ACCESS } from "@/helpers/permissionsConstant";

export default [
  {
    path: "/boards/:board/tasks/:taskId/:task/subtasks/:id/:subtask",
    name: "admin-board-task-subtask-details",
    component: () =>
      import(
        "@/views/admin/task-management/milestone-board/sub-task/SubTaskDetailsView.vue"
      ),
    meta: {
      gate: MILESTONE_BOARD_ACCESS,
      requiresAuth: true,
      pageTitle: "Sub Task Details",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Board",
          active: false,
          to: "/admin/task/boards",
        },
        {
          text: "Board Details",
          active: false,
          to: "/admin/task/board/:board",
        },
        {
          text: ":task",
          active: false,
          to: "/boards/:board/tasks/:taskId/:task",
        },
        {
          text: ":subtask",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/subtask/:id/details",
    name: "admin-task-board-task-subtask-details",
    component: () =>
      import(
        "@/views/admin/task-management/milestone-board/sub-task/SubTaskDetailsView.vue"
      ),
    meta: {
      gate: MILESTONE_BOARD_ACCESS,
      requiresAuth: true,
      pageTitle: "Sub Task Details",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Board",
          active: false,
          to: "/admin/task/boards",
        },
        {
          text: "Board Details",
          active: true,
          to: "",
        },
        {
          text: "Task",
          active: true,
          to: "",
        },
        {
          text: "Sub Task",
          active: true,
          to: "",
        },
        {
          text: "Sub Task Details",
          active: true,
          to: "",
        },
      ],
    },
  },

  {
    path: "/admin/task/board/task/subtask/:id/details",
    name: "admin-task-board-task-subtask-old-details",
    component: () =>
      import(
        "@/views/admin/task-management/milestone-board/sub-task/SubTaskDetailsView.vue"
      ),
    meta: {
      gate: MILESTONE_BOARD_ACCESS,
      requiresAuth: true,
      pageTitle: "Sub Task Details",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Board",
          active: false,
          to: "/admin/task/boards",
        },
        {
          text: "Board Details",
          active: true,
          to: "",
        },
        {
          text: "Task",
          active: true,
          to: "",
        },
        {
          text: "Sub Task",
          active: true,
          to: "",
        },
        {
          text: "Sub Task Details",
          active: true,
          to: "",
        },
      ],
    },
  },
];
