export const boardModule = {
  namespaced: true,
  state: {
    filters: {
      filterProjectId: "",
      filterBoardListMilestoneId: "",
      filterMilestoneStatus: "",
      filterDeadline: "",
      filterAllMemberSupervisorId: "",
    },
  },
  getters: {
    getFilters: function (state) {
      return state.filters;
    },
  },
  mutations: {
    setFilter: function (state, payload) {
      state.filters[payload.key] = payload.value;
    },
    resetFilters: function (state) {
      state.filters = {
        filterProjectId: "",
        filterBoardListMilestoneId: "",
        filterMilestoneStatus: "",
        filterDeadline: "",
        filterAllMemberSupervisorId: "",
      };
    },
  },
  actions: {
    setFilter: function (context, payload) {
      // Pass both key and value in the payload
      context.commit("setFilter", {
        key: payload.key,
        value: payload.value,
      });
    },
    resetFilters: function (context) {
      context.commit('resetFilters');
    },
  },
};
