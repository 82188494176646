import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { permissionAbility } from "@/helpers/helpers";
import {
  DASHBOARD_ACCESS,
  USER_DASHBOARD_ACCESS,
} from "@/helpers/permissionsConstant";
import attendanceRouter from "./attendanceRouter";
import leaveRouter from "./leaveRouter";
import leadRouter from "./leadRouter";
import financeRouter from "./financeRouter";
import taskRouter from "./taskRouter";
import assetRouter from "./assetRouter";
import employeeRouter from "./employeeRouter";
import settingRouter from "./settingRouter";
import authRouter from "./authRouter";
import rolesRouter from "./rolesRouter";
import divisionRouter from "./divisionRouter";
import departmentRouter from "./departmentRouter";
import designationRouter from "./designationRouter";
import usersRoute from "./usersRoute";
import noticeRouter from "./noticeRouter";
import notificationRouter from "./notificationRouter";
import payrollRouter from "./payrollRouter";
import subscriptionRouter from "./subscriptionRouter";

import axios from "@/helpers/axios";
import subTaskRouter from "./subTaskRouter";
import loanRouter from "./loanRouter";
import outreachRouter from "./outreachRouter";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "admin-dashboard",
      component: () => import("@/views/admin/AdminDashboardView.vue"),
      meta: {
        gate: DASHBOARD_ACCESS,
        requiresSuperAuth: true,
        requiresAuth: true,
        pageTitle: "",
        homePatch: "/admin/dashboard",
      },
    },
    {
      path: "/admin/dashboard",
      name: "admin-dashboard",
      component: () => import("@/views/admin/AdminDashboardView.vue"),
      meta: {
        gate: DASHBOARD_ACCESS,
        requiresSuperAuth: true,
        requiresAuth: true,
        pageTitle: "",
        homePatch: "/admin/dashboard",
      },
    },
    {
      path: "/admin/company",
      name: "company-management",
      component: () => import("@/views/admin/company/CompanyView.vue"),
      meta: {
        requiresAuth: true,
        requiresSuperAuth: true,
        pageTitle: "",
        homePatch: "/admin/company",
      },
    },
    {
      path: "/admin/help",
      name: "help-section",
      component: () => import("@/views/admin/faq/Faq.vue"),
      pageTitle: "FAQ",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "FAQ",
          active: true,
        },
      ],
    },
    // {
    //   path: "/dashboard",
    //   name: "dashboard",
    //   component: () => import("@/views/auth/AuthDashboardView.vue"),
    //   meta: {
    //     gate: USER_DASHBOARD_ACCESS,
    //     requiresAuth: true,
    //     pageTitle: "Dashboard",
    //     homePatch: "/dashboard",
    //     breadcrumb: [
    //       {
    //         text: "Dashboard",
    //         active: true,
    //         to: "",
    //       },
    //     ],
    //   },
    // },
    ...usersRoute,
    ...employeeRouter,
    ...divisionRouter,
    ...departmentRouter,
    ...rolesRouter,
    ...designationRouter,
    ...attendanceRouter,
    ...leaveRouter,
    ...leadRouter,
    ...taskRouter,
    ...subTaskRouter,
    ...financeRouter,
    ...assetRouter,
    ...notificationRouter,
    ...noticeRouter,
    ...settingRouter,
    ...authRouter,
    ...payrollRouter,
    ...loanRouter,
    ...subscriptionRouter,
    ...outreachRouter,
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-403",
      name: "error-403",
      component: () => import("@/views/error/Error403.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// global auth guard
router.beforeEach(async (to, from, next) => {
  // dynamic title
  window.document.title =
    to.meta && to.meta?.pageTitle
      ? `${process.env.VUE_APP_TITLE} | ${to.meta?.pageTitle}`
      : `${process.env.VUE_APP_TITLE}`;

  const isAuthenticated = store.getters["authModule/getIsAuthenticated"];
  const user = store.getters["userModule/getUser"];

  if (to.meta.requiresSuperAuth && user?.super_admin === 1) {
    next();
    return;
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isAuthenticated) {
      try {
        // get user  permission
        const resUser = await axios.post(
          "/api/user?include=permissions,designation,roles,department"
        );

        const { permissions, ...user } = resUser?.data?.data;
        const mapPermissions = permissions?.data.map((item) => item.name);
        await store.dispatch("userModule/setUser", {
          user,
        });

        await store.dispatch("userModule/setPermissions", {
          permissions: mapPermissions,
        });

        // permission page
        if (to.matched.some((record) => record.meta.gate)) {
          const permissions = store.getters["userModule/getPermissions"];
          if (to.meta?.gate && permissionAbility(to.meta.gate, permissions)) {
            next();
          } else {
            next("/error-403");
          }
        } else {
          next(false);
        }
      } catch (error) {
        await store.dispatch("authModule/setIsAuthenticated", {
          isAuthenticated: false,
          token: null,
        });

        await store.dispatch("authModule/clearPersistedState");

        await store.dispatch("userModule/removeUser");

        await store.dispatch("userModule/setPermissions", {
          permissions: null,
        });

        window.location.href = "/";
      }
    } else {
      next({ name: "login" });
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (isAuthenticated) {
      next({ name: "auth-dashboard" });
    } else {
      next();
    }
  } else {
    next();
  }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
