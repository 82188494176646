import { MILESTONE_BOARD_ACCESS } from "@/helpers/permissionsConstant";
export default [
  {
    path: "/admin/task/boards",
    name: "admin-task-boards",
    component: () =>
      import(
        "@/views/admin/task-management/milestone-board/MilestoneTaskBoardListView.vue"
      ),
    meta: {
      gate: MILESTONE_BOARD_ACCESS,
      requiresAuth: true,
      pageTitle: "Board",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Board",
          active: true,
          to: "",
        },
      ],
    },
  },

  {
    path: "/admin/task/board/:id/phase-duration",
    name: "admin-task-board-phase-duration",
    component: () =>
      import(
        "@/views/admin/task-management/milestone-board/MilestoneTaskBoardPhaseDurationView.vue"
      ),
    meta: {
      gate: MILESTONE_BOARD_ACCESS,
      requiresAuth: true,
      pageTitle: "Phase Duration",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Board",
          active: false,
          to: "/admin/task/boards",
        },
        {
          text: "Board Details",
          active: false,
          to: "/admin/task/board/:id",
        },
        {
          text: "Phase Duration",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/task/global-task-activity-tracker",
    name: "admin-global-task-activity-tracker",
    component: () =>
      import(
        "@/views/admin/task-management/milestone-board-global-list/MilestoneBoardGlobalList.vue"
      ),
    meta: {
      gate: MILESTONE_BOARD_ACCESS,
      requiresAuth: true,
      pageTitle: "Global Task Activity Tracker",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Global Task Activity Tracker",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/task/employee-global-task-tracker",
    name: "employee-global-task-tracker",
    component: () =>
      import(
        "@/views/admin/task-management/milestone-board-global-list/EmployeeGlobalTaskTracker.vue"
      ),
    meta: {
      gate: MILESTONE_BOARD_ACCESS,
      requiresAuth: true,
      pageTitle: "All Task",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "All Task",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/task/board/:id",
    name: "admin-task-board-details",
    component: () =>
      import(
        "@/views/admin/task-management/milestone-board/MilestoneTaskBoardDetailsViewDev.vue"
      ),
    meta: {
      gate: MILESTONE_BOARD_ACCESS,
      requiresAuth: true,
      pageTitle: "Board Details",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Board",
          active: false,
          to: "/admin/task/boards",
        },
        {
          text: "Board Details",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/task/board/:id/dev",
    name: "admin-task-board-details-dev",
    component: () =>
      import(
        "@/views/admin/task-management/milestone-board/MilestoneTaskBoardDetailsViewDev.vue"
      ),
    meta: {
      gate: MILESTONE_BOARD_ACCESS,
      requiresAuth: true,
      pageTitle: "Board Details",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Board",
          active: false,
          to: "/admin/task/boards",
        },
        {
          text: "Board Details",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/task/project/trash",
    name: "admin-task-board-trash",
    component: () =>
      import(
        "@/views/admin/task-management/milestone-board/MilestoneTrashView.vue"
      ),
    meta: {
      gate: MILESTONE_BOARD_ACCESS,
      requiresAuth: true,
      pageTitle: "Board Trash",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Project",
          active: false,
          to: "/admin/task/project",
        },
        {
          text: "Board Trash",
          active: true,
          to: "",
        },
      ],
    },
  },

  {
    path: "/admin/task/board/:id/phases",
    name: "admin-task-board-phases",
    component: () =>
      import(
        "@/views/admin/task-management/milestone-board/phase/PhaseView.vue"
      ),
    meta: {
      gate: MILESTONE_BOARD_ACCESS,
      requiresAuth: true,
      pageTitle: "Phase",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Board",
          active: false,
          to: "/admin/task/boards",
        },
        {
          text: "Board Details",
          active: false,
          to: "/admin/task/board/:id",
        },
        {
          text: "Phase",
          active: true,
          to: "",
        },
      ],
    },
  },

  {
    path: "/admin/task/board/:id/backlogs",
    name: "admin-task-board-backlog",
    component: () =>
      import(
        "@/views/admin/task-management/milestone-board/backlog/BacklogView.vue"
      ),
    meta: {
      gate: MILESTONE_BOARD_ACCESS,
      requiresAuth: true,
      pageTitle: "Backlog",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Board",
          active: false,
          to: "/admin/task/boards",
        },
        {
          text: "Board Details",
          active: false,
          to: "/admin/task/board/:id",
        },
        {
          text: "Backlog",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/task/board/:id/history",
    name: "admin-task-board-history",
    component: () =>
      import(
        "@/views/admin/task-management/milestone-board/MilestoneTaskBoardHistoryView.vue"
      ),
    meta: {
      gate: MILESTONE_BOARD_ACCESS,
      requiresAuth: true,
      pageTitle: "Board History",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Board",
          active: false,
          to: "/admin/task/boards",
        },
        {
          text: "Board Details",
          active: false,
          to: "/admin/task/board/:id",
        },
        {
          text: "Board History",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/task/:id/:task",
    name: "admin-task-board-task-details",
    component: () =>
      import(
        "@/views/admin/task-management/milestone-board/task/TaskDetailsView.vue"
      ),
    meta: {
      gate: MILESTONE_BOARD_ACCESS,
      requiresAuth: true,
      pageTitle: "Task Details",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Board",
          active: false,
          to: "/admin/task/boards",
        },
        {
          text: "Board Details",
          active: true,
          to: "",
        },
        {
          text: "Task",
          active: true,
          to: "",
        },
        {
          text: "Task Details",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/boards/:board/tasks/:id/:task",
    name: "admin-board-task-details",
    component: () =>
      import(
        "@/views/admin/task-management/milestone-board/task/TaskDetailsView.vue"
      ),
    meta: {
      gate: MILESTONE_BOARD_ACCESS,
      requiresAuth: true,
      pageTitle: "Task Details",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Board",
          active: false,
          to: "/admin/task/boards",
        },
        {
          text: "Board Details",
          active: false,
          to: "/admin/task/board/:board",
        },
        {
          text: ":task",
          active: true,
          to: "",
        },
      ],
    },
  },

  {
    path: "/admin/task/board/:id/phases/tasks/trash",
    name: "admin-task-board-tasks-trash",
    component: () =>
      import(
        "@/views/admin/task-management/milestone-board/task/TaskTrashView.vue"
      ),
    meta: {
      gate: MILESTONE_BOARD_ACCESS,
      requiresAuth: true,
      pageTitle: "Task Trash",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Board Details",
          active: false,
          to: "/admin/task/board/:id",
        },
        {
          text: "Trash",
          active: true,
          to: "",
        },
      ],
    },
  },
];
