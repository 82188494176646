import {
  ATTENDANCE_POLICY_ACCESS,
  ATTENDANCE_POLICY_MAP_ACCESS,
  ATTENDANCE_CALENDER_ACCESS,
  ATTENDANCE_ACCESS,
  ATTENDANCE_SHOW,
  ATTENDANCE_RECONCILIATION_ACCESS, ATTENDANCE_CHECK_IN_OUT, REMOTE_ATTENDANCE_ACCESS,
} from "@/helpers/permissionsConstant";

export default [
  {
    path: "/admin/attendance",
    name: "admin-attendance",
    component: () => import("@/views/admin/attendance/AttendanceView.vue"),
    meta: {
      gate: ATTENDANCE_ACCESS,
      requiresAuth: true,
      pageTitle: "Attendance",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Attendance",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/remote-attendance",
    name: "admin-remote-attendance",
    component: () => import("@/views/admin/attendance/RemoteAttendanceView.vue"),
    meta: {
      gate: REMOTE_ATTENDANCE_ACCESS,
      requiresAuth: true,
      pageTitle: "Remote Attendance",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Remote Attendance",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/attendance/reconciliations",
    name: "admin-attendance-reconciliations",
    component: () =>
      import("@/views/admin/attendance/reconciliation/ReconciliationView.vue"),
    meta: {
      gate: ATTENDANCE_RECONCILIATION_ACCESS,
      requiresAuth: true,
      pageTitle: "Attendance Reconciliation",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Attendance Reconciliation",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/attendance/report",
    name: "admin-attendance-report",
    component: () =>
      import("@/views/admin/attendance/AttendanceReportView.vue"),
    meta: {
      gate: ATTENDANCE_ACCESS,
      requiresAuth: true,
      pageTitle: "Attendance Report",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Report",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/attendance/calender",
    name: "admin-attendance-calender",
    component: () =>
      import("@/views/admin/attendance/AttendanceCalenderView.vue"),
    meta: {
      gate: ATTENDANCE_CALENDER_ACCESS,
      requiresAuth: true,
      pageTitle: "Attendance Calender",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Attendance Calender",
          active: true,
          to: "",
        },
      ],
    },
  },

  {
    path: "/admin/attendance/policy",
    name: "admin-attendance-policy",
    component: () =>
      import(
        "@/views/admin/attendance/attendancePolicy/AttendancePolicyView.vue"
      ),
    meta: {
      gate: ATTENDANCE_POLICY_ACCESS,
      requiresAuth: true,
      pageTitle: " Attendance Policy",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: " Attendance Policy",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/attendance/policy/map",
    name: "admin-attendance-policy-map",
    component: () =>
      import(
        "@/views/admin/attendance/attendancePolicy/AttendancePolicyMapView.vue"
      ),
    meta: {
      gate: ATTENDANCE_POLICY_MAP_ACCESS,
      requiresAuth: true,
      pageTitle: " Attendance Policy Map",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Policy",
          active: false,
          to: "/admin/attendance/policy",
        },
        {
          text: "Policy Map",
          active: true,
          to: "",
        },
      ],
    },
  },

  {
    path: "/admin/attendance/:id",
    name: "admin-attendance-details",
    component: () =>
      import("@/views/admin/attendance/AttendanceDetailsView.vue"),
    meta: {
      gate: ATTENDANCE_SHOW,
      requiresAuth: true,
      pageTitle: "Attendance Details",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Attendance",
          active: false,
          to: "/admin/attendance",
        },
        {
          text: "Attendance Details",
          active: true,
          to: "",
        },
      ],
    },
  },
];
