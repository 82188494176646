import {
  SUBSCRIPTION_ACCESS,
  PRICING_TABLE_ACCESS,
  SUBSCRIPTION_EDIT,
  PAYMENT_HISTORY_ACCESS,
} from "@/helpers/permissionsConstant";

export default [
  {
    path: "/pricing",
    name: "pricing-table",
    component: () => import("@/views/admin/subscription/Subscription.vue"),
    meta: {
      gate: PRICING_TABLE_ACCESS,
      requiresAuth: true,
      pageTitle: "Pricing Tables",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Pricing Tables",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/payment-histories",
    name: "payment-histories",
    component: () =>
      import("@/views/admin/subscription/PaymentHistoriesView.vue"),
    meta: {
      gate: PAYMENT_HISTORY_ACCESS,
      requiresAuth: true,
      pageTitle: "Payment Histories",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Payment Histories",
          active: true,
          to: "",
        },
      ],
    },
  },
];
