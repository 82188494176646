<template>
  <div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import IntroJs from 'intro.js';
import 'intro.js/introjs.css';
import {EventBus} from "@/helpers/event-bus";

export default {
  name: 'Intro',
  data () {
    return {
      globalSteps: [
        {
          element: '.dashboard-tour',
          intro: 'Discover the Dashboard, your command center for navigating through the application!',
          position: 'right'
        },
        {
          element: '.role-tour',
          intro: 'Find the Role Management section to define user roles and manage permissions.',
          position: 'right'
        },
        {
          element: '.hrms-tour',
          intro: 'Explore the HRMS module for employee, attendance and leave management.',
          position: 'right'
        },
        {
          element: '.lead-tour',
          intro: 'Manage your potential customers in the Leads Management section!',
          position: 'right'
        },
        {
          element: '.finance-tour',
          intro: 'Utilize the Finance module for  Project and financial management.',
          position: 'right'
        },
        {
          element: '.task-tour',
          intro: 'Track and manage tasks effectively with the Task Management section.',
          position: 'right'
        },
        {
          element: '.asset-tour',
          intro: 'Keep track of company assets in the Asset Management module.',
          position: 'right'
        },
        {
          element: '.loan-tour',
          intro: 'Manage employee loans and repayment schedules in the Loan Management section.',
          position: 'right'
        },
        {
          element: '.payroll-tour',
          intro: 'Automate salary calculations with the Payroll Management module.',
          position: 'right'
        },
        {
          element: '.settings-tour',
          intro: 'Configure application preferences in the Settings module.',
          position: 'right'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      authUser: "userModule/getUser",
    }),
  },
  mounted() {

    EventBus.$on('start-global-tour', () => {

      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Smooth scrolling
      });

      setTimeout(() => {
        this.startTour()
      }, 1000)

    })

      if (this.authUser?.admin && !this.authUser?.visited) {

        setTimeout(() => {
          this.startTour()
        }, 1500)

        // Call API to mark settings as visited
        this.$api.put(`api/users/visited`);
      }

  },
  methods: {
    startTour(){

      this.globalSteps = this.globalSteps.filter(step => document.querySelector(step.element))

        const intro = IntroJs();
        intro.setOptions({
          steps: this.globalSteps,
          showStepNumbers: true,
          showBullets: true,
          exitOnOverlayClick: false,
          highlightClass: 'navbar-intro',
          overlayOpacity: 0,
          hidePrev: true,
          hideNext: true,
          nextToDone: true,
          nextLabel: 'Next',
          prevLabel: 'Prev',
          scrollToElement: true,
        });

        intro.start();


    },
  }
}
</script>

<style>
.introjs-helperLayer.navbar-intro {
  background: transparent!important;
}

.introjs-helperLayer.navbar-intro:before {
  content: '';
  position: fixed;
  width: inherit;
  height: inherit;
  border-radius: 0.5em;
  box-shadow: 0 0 0 1000em rgba(0, 0, 0, 0.7);
  opacity: 1;
}

.introjs-button {
  background: #7367f0 !important;
  padding: 8px 10px !important;
  font-size: 12px !important;
  border-radius: 8px !important;
  color: white !important;
  margin:0 8px !important;
  margin-top: 3px !important;
}

.introjs-button:hover {
  background: #6356d9;
  cursor: pointer;
  color: white !important;
}

.introjs-button:active {
  background: #6356d9;
  color: white !important;
  border: 0px solid transparent;
}

.introjs-button:focus {
  background: #6356d9;
  color: white !important;
  border: 0px solid transparent;
}



</style>